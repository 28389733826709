<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Tracking Purchase Order</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <!-- <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Customer</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <v-select id="Customer" class="pb-3" :options="CustomerData" v-model="Customer" @input="onChangeCustomer()"/>
                                <label id="errorCustomer" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol> 
                        </CRow> -->
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <tracking-purchase-order-grid ref="trackingPurchaseOrderGrid" 
                            :key="gridReload" 
                            :dateTo="DateTo" 
                            :dateFrom="DateFrom"
                        />
                    </CCol>
                </CRow>
                
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';
import { report } from '../../../infrastructure/constant/variable';
// import TrackingPurchaseOrderServices from './Script/TrackingPurchaseOrdeService.js';
import TrackingPurchaseOrderGrid from './Grid//TrackingPurchaseOrderGrid.vue';

export default {
    name: 'TrackingPurchaseOrder',
    components: {
        'date-picker' : datePicker,
        'tracking-purchase-order-grid' : TrackingPurchaseOrderGrid,
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();
        this.DateType = report.default_date;

        this.onChangeDateType();

        var dateFrom = moment().startOf('month').format('YYYY-MM-DD');
        var dateTo = moment().endOf('month').format('YYYY-MM-DD');

        this.DateFrom = dateFrom;
        this.DateTo = dateTo;

        // this.CustomerData = await TrackingPurchaseOrderReportServices.getCustomerQuery();
        // this.Customer = null;
    },
    data() {
        return {
            DateTypeData: [],
            DateType: '',
            DateFrom: '',
            DateTo: '',

            CustomerData: [],
            Customer: '',
            
            gridReload: 0,
        }
    },
    methods: {
        // async onChangeCustomer(){
        //     if(this.Customer == '') {
        //         this.Customer = null;
        //     }
        //     this.gridReload++;
        // },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            this.gridReload++;
            this.DateType = 'Custom';
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            this.gridReload++;
            this.DateType = 'Custom';
        },
        async exportExcel() {
            this.$refs.trackingPurchaseOrderGrid.exportExcel(this.DateFrom, this.DateTo);
        },
    }
}
</script>

<style scoped>
.sm-2, .sm-3, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .sm-2, .sm-3, .col-sm-half {
        float: left;
    }
    .sm-2 {
        width: 12%;
    }
    .sm-3 {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

</style>